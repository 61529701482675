import logo from './logo.svg';
import './App.css';
import { useState, useEffect} from 'react';


function App() {

  const [btnState, setbtnState] = useState(false);
  const [url, seturl] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, seterror] = useState({
    'error': true,
    'msg': ""
  });
  const handleChange = (e) => {
    console.log(e.target.value)
    seturl(e.target.value)
  }

  function getVideo(){
    setbtnState(true)
    if(url == ""){
      seterror({'error': true, "msg": "Please enter Username"})
      console.log("No Hello")
    
    }
    else{
    
        fetchData(); // Call the fetchData function when the component mounts
    
    }
  }
  const fetchData = async () => {
    try {
      const response = await fetch(`https://apps.savereel.in/story/?user=${url}/`, {
          method: 'GET',
      });
      const result = await response.text()
      console.log(result);

      setData(result);
      setLoading(false);
      setbtnState(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setbtnState(false);
      setLoading(false);
    }
  };

 
  return (
    
    <div className="App">
      {console.log("App Render")}
      <header className="App-header">
       
          <div className="searchbox-wrap">
            <input onChange={handleChange} type="text" placeholder="Enter Username" className='url' />
            <button className='btn' disabled={btnState} onClick={getVideo}>Get</button>
       
        </div>
        {error.error && <Error msg={error.msg}/>}
      </header>
    </div>
  );
}

export default App;



function Error(props) {
  return (
    <div>{props.msg}</div>
  )
}

